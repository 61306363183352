
import { Route, Router,ActivatedRoute } from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injectable,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild,inject,ElementRef,
} from '@angular/core'
import { NotificationService } from '../../notification.service'
import {
  buildResultList,
  buildController,
  ResultListState,
  loadQueryActions,
  loadSearchActions,
  loadSearchAnalyticsActions,
  loadAdvancedSearchQueryActions,
  loadFacetSetActions
} from '@coveo/headless'
import { EngineService } from '../../engine.service'
import { ResultsPageComponent } from './../../results-page/results-page.component'
import { CONFIG } from 'src/environments/environment.development'
import { OnedriveService } from 'src/app/services/onedrive.service'
import { CollectionsComponent } from 'src/app/collections/collections.component'
import { SavedsearchComponent } from 'src/app/savedsearch/savedsearch.component'
import { AuthService } from 'src/app/auth.service'
import { CollectionsService } from 'src/app/collections.service'
import { Customeventlogging } from 'src/app/customevent-logging.service';
import { SharedService } from 'src/app/shared.service';
import { ENVIRONMENT } from 'src/environments/environment.development';
import { CookieServiceWrapper } from 'src/app/cookie-service-wrapper.service';

@Component({
  selector: 'app-results-page-header',
  templateUrl: './results-page-header.component.html',
  styleUrls: ['./results-page-header.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ResultsPageHeaderComponent implements OnInit, AfterViewInit {
  isPatternDeleted = false
  private document:any = inject(DOCUMENT);
  notificationsCount: number = 0
  showNotificationCount = false
  notificationToggle = false
  notifications: any[] = []
  expandless: boolean = false
  expandmore: boolean = true
  searchId: string = 'expand_more'
  loader: boolean = false
  loadMore: boolean = true
  clearSavedSearch: boolean = false
  engineObj: any
  actionCreators: any
  patternName: any = ''
  currentSearchId: any
  clearSavedSearchOperation: any = false
  clearAllNotifications: any = false
  clearAllNotificationsText: any =
    CONFIG.NOTIFICATIONS.CLEARALLNOTIFICATIONS_TEXT
  clearAllNotificationsFlag: any = true
  widthOfProgress = 0
  title = ''
  body = ''
  showPopup = false
  export= false;
  exportPopUp = true;

  @Output("mydrive") mydrive: EventEmitter<any> = new EventEmitter();
  @Output("mycollections") mycollections: EventEmitter<any> = new EventEmitter();

   closeExportPopup(){
    this.export = false;
    //this.exportPopUp = false;
   }
  feature = 'manualSync'
  loadCollectionsPartial = false
  loadSavedSearchPartial = false
  userid:any;
  isRockyMountainUser = false;


  queryPayLoad = {
    locale: 'en-US',
    searchHub: 'AdvanceSearch',
    tab: '',
    cq: '',
    aq: '',
    q: '',
    groupBy: [
      {
        field: '@contentapprovaldate',
        sortCriteria: 'AlphaAscending'
      }
    ]
  }
  @Injectable({
    providedIn: 'root'
  })
  updates: any[] = []
  @ViewChild(CollectionsComponent) collectionComp!: CollectionsComponent
  @ViewChild(SavedsearchComponent) savedSearchComp!: SavedsearchComponent

  
  constructor (
    private renderer: Renderer2,
    private notificationService: NotificationService,
    private oneDriveService: OnedriveService,
    private engine: EngineService,
    private resultsPageComp: ResultsPageComponent,
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef,
    private Auth:AuthService,
    private collectionService:CollectionsService,
    private router:Router,
    private cookieService: CookieServiceWrapper,
    private elemRef:ElementRef,
    private route: ActivatedRoute,
    private eventLoggingService: Customeventlogging,
    private sharedService : SharedService,
    private cdr : ChangeDetectorRef
  ) {
    if(sessionStorage.getItem("isRockyMountainUser") == null || sessionStorage.getItem("isRockyMountainUser") == "null" ){
      this.router.navigate(['/auth']);
     }
     else{
      var userType = sessionStorage.getItem("isRockyMountainUser");
       if(userType == "true"){
            this.isRockyMountainUser = true;
       }
     }
    this.engineObj = engine.getEngibeObj();
  }
  ngAfterViewInit (): void {

    if(this.cookieService.get("cfl")){
      var cfl = this.cookieService.get("cfl");
      if(cfl=="mydrive"){
        this.loadOneDriveDocs();
        this.cookieService.set("cfl","");
      }else if(cfl=="collections"){
        this.loadAllCollections();
        this.cookieService.set("cfl","");
      }else if(cfl=="savedsearch"){
        this.showSearches ();
        this.cookieService.set("cfl","");
      }else if(cfl=="notifications"){
        this.openNotifications();
        this.cookieService.set("cfl","");
      }
     }

    this.cdRef.detectChanges();
  }

  closeMainTab () {
    this.notificationToggle = false
  }
  userId : any;
  nickName : any;
  fullName : any;
  notificationsOpenFromLink:boolean = false;
  public ngOnInit () {
    this.userId = this.Auth.getUserId();
    this.nickName = this.cookieService.get('sn');
    this.fullName = this.cookieService.get('fn');
    this.userid= this.Auth.getUserId();
    this.getSavedSearchesNotificationsCount()
    this.getCollectionsDocsFromServer();
    this.getOneDriveDocsFromServer();

    const queryParams = this.route.snapshot.queryParams;
  
    // Access individual query parameters
    this.notificationsOpenFromLink = queryParams['notifications'];

     if(this.notificationsOpenFromLink){
       this.openNotifications();
     }


     
     

  }

  expandNotificationRecord (notification: any) {
    if (notification.collapse == 'expand_more') {
      notification.collapse = 'expand_less'
      this.getUpdatesBasedonSavedSearches(notification.ID)
      return
    }
    if (notification.collapse == 'expand_less') {
      notification.collapse = 'expand_more'
      this.updates = []
      return
    }
  }
   exportToExcel(){
       // this.router.navigateByUrl("/exporttoExcel");
       this.export = true;
  }

  async loadMoreNotifications () {
    //CONFIG.TEMP.USERID
    
    var requestPayload =
      `{
     "userId": "` +this.userid+
      `",
     "markAsViewed": false,
     "updateId": "",
     "loadmore":"1",
     "loadall":"` +
      1 +
      `"
   }`

    var response: any = await this.notificationService.loadMoreNotifications(
      requestPayload
    )
    // console.log('response loadMoreNotifications', response)
    let allNotifications = response.map((notification: any) => {
      notification.collapse = 'expand_more'

      return notification
    })
    this.notifications = allNotifications
    this.getSavedSearchesNotificationsCount()
    this.loadMore = false
  }
  isDropdownVisible = false;

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
  getSavedSearchesNotificationsCount () {
    this.ngZone.run(() => {
      let requestPayload = {
        userId: this.userid,
        markAsViewed: false,
        updateId: '',
        getCount: true,
        loadall: '0'
      }
      this.notificationService.getNotificationsCount(requestPayload).subscribe({
        next: (response: any) => {
          // console.log('response', response)
          this.notificationsCount = response?.[0]?.count
          if (this.notificationsCount > 0) {
            this.showNotificationCount = true
          }
        },
        error: e => console.error(e),
        complete: () => console.info('complete')
      })
    })
  }

  checkValue:any;
  parentHandler(eventData){
    this.checkValue = eventData;
    // console.log("check", this.checkValue);
  }

  //************************************** */

  // showNotificationRelatedResults (recordId: any) {
  //   // const engine = this.engine.getEngibeObj();
  //   // const {updateQuery} = loadQueryActions(engine);
  //   // const {executeSearch} = loadSearchActions(engine);
  //   // const {logSearchboxSubmit} = loadSearchAnalyticsActions(engine);
  //   // engine.dispatch(updateQuery({q: "@contentsourceid="+recordId}));
  //   // engine.dispatch(executeSearch(logSearchboxSubmit()));

  //   this.resultsPageComp.loadResultsOnQuery('@contentdocumentnumber=' + recordId +" OR @spouniqueid="+ recordId );
  //   //  this.resultsPageComp.loadResultsOnQuery('example');
  //   //  return;

  //   //      recordId = parseInt(recordId);
  //   // var requestPayload = `{"userId":"`+CONFIG.TEMP.USERID+`","markAsViewed":true,"updateId":"`+recordId+`"}`;
  //   //   var response=   await this.notificationService.markNotificationAsViewed(requestPayload);
  //   //   // console.log("response", response);
  // }
  async showNotificationRelatedResults (recordId: any, id) {
    this.clearAllFilters();
    // this.clearAllFn();
     this.clearAllSearch();
    // console.log("recordId", recordId);
   // console.log("id:", id);
// const engine = this.engine.getEngibeObj();
// const {updateQuery} = loadQueryActions(engine);
// const {executeSearch} = loadSearchActions(engine);
// const {logSearchboxSubmit} = loadSearchAnalyticsActions(engine);
// engine.dispatch(updateQuery({q: "@contentsourceid="+recordId}));
// engine.dispatch(executeSearch(logSearchboxSubmit()));


this.resultsPageComp.loadResultsOnQuery('@contentdocumentnumber=' + recordId +" OR @spouniqueid="+ recordId );
//  this.resultsPageComp.loadResultsOnQuery('example');
//  return;

//      recordId = parseInt(recordId);
var requestPayload = `{"userId":"`+this.userid+`","markAsViewed":true,"updateId":"`+id+`"}`;
var response=   await this.notificationService.markNotificationAsViewed(requestPayload);
// console.log("response", response);
this.getSavedSearchesNotificationsCount()
}
  async getUpdatesBasedonSavedSearches (searchId: any) {
    let offset = 0
    let response: any
    let counter = 0
    var listItems ="";
    var ids:any = [];
    var removeNotificationIds:any = [];
     this.updates = [];
    do {
      let requestPayload = {
        userId: this.userid,
        markAsViewed: false,
        updateId: '',
        searchId: searchId,
        offset: offset,
        loadall: '0'
      }
      response = await (
        await this.notificationService.getNotificationsBasedOnSearchId(
          requestPayload
        )
      ).toPromise()
      // console.log('response check', response)
      this.updates = [...this.updates, ...response]
      // // console.log('updates length:', this.updates.length)
      // offset = offset + response.length
      var notification_id = this.document.getElementById(searchId);

      for(var i=0;i<response.length;i++){
        var update = response[i];
        //// console.log("update", update);
       var color = update.VISITED == 1 ? '#C6C6C6':'teal';
       ids.push("notification_"+update.UPDATED_DOCS_NUMBER+"_"+update.ID);
       removeNotificationIds.push("remove_"+update.ID)
      // // console.log("color", color);
      listItems+= `<li  (click)="showNotificationRelatedResults("${update.UPDATED_DOCS_NUMBER}")" style="display: flex;justify-content: space-between;align-items: center; padding:5px,12px,5px,12px; font-size:14px; line-height:22px; font-weight:700; margin-bottom:20px">
       <span id="notification_${update.UPDATED_DOCS_NUMBER}_${update.ID}" class="${update.UPDATED_DOCS_ID}" style=" color:${color};  font-weight: 400; overflow: auto; text-decoration: underline; cursor: pointer;">1 new file(s) on asset: ${update.UPDATED_DOCS}</span> <span  id="remove_${update.ID}" (click)="removeNotification(${update.ID})"  style=" margin-left:10px; margin-right:-13px; font-size:12px; font-weight: 400;cursor: pointer;color:${color};">X</span>
      </li>`
     }

      if (response.length == 10) {
        this.loader = true
        //listItems= listItems + "<span class='loader'>Loading...</span>";
      } else {
        this.loader = false
      }
      if(notification_id != null){
        notification_id.innerHTML = listItems;
        }
        offset = offset + response.length;
        for(var z=0;z<ids.length;z++){
          // var acutalId = ids[z].split("_")?.[1];
           var elm = this.elemRef.nativeElement.querySelector(`#${ids[z]}`);
           //// console.log("elm", elm);
           elm.addEventListener('click', () =>{
            //// console.log("acutalId", acutalId);
            // // console.log("event",event?.target?.id)
            var element:any = event?.target;
            this.closeMainTab();
             //// console.log("element.id", element.id.split("_"));
             var elementId = element.id.split("_");
             //// console.log("elementId", elementId?.[1], elementId?.[2]);
             this.showNotificationRelatedResults(elementId?.[1], elementId?.[2]);
           })
    
          }
          for(var z1=0;z1<removeNotificationIds.length;z1++){
            // var acutalId = ids[z].split("_")?.[1];
             let elm = this.elemRef.nativeElement.querySelector(`#${removeNotificationIds[z1]}`);
             //// console.log("elm remove", elm);
             elm.addEventListener('click', () =>{
              //// console.log("acutalId", acutalId);
              // // console.log("event",event?.target?.id)
              let element:any = event?.target;
              // // console.log("element.id", element.id.split("_"));
               let elementId = element.id.split("_");
               //// console.log("elementId", elementId?.[1]);
              let response:any =  this.removeNotification(elementId?.[1]);
              if(response != null){
                     element.parentNode.remove();
                     this.getSavedSearchesNotificationsCount();
                     //element.remove();
              }
              // this.showNotificationRelatedResults(elementId?.[1], elementId?.[2]);
             })
      
            }
      await this.sleep(5000)
      counter++
      // console.log('do block called', counter)
    } while (response.length == 10)
    this.loader = false;
    this.updates= [];
    // console.log("Ids length", ids.length)
  }

  closeClearAllTab (input:any) {
    // if(input ==0)
     this.clearAllNotifications = false
    // if(input == 1){
    //   this.
    // }
  }

  getSearchTerm(e:any){
    // console.log(e);
  }

  
  async onConfirmationClearAllNotifications () {
    var requestPayload =
      `{
      "userId": "` +this.userid+
      `",
      "markAsViewed": false,
      "updateId": "",
      "loadmore":"1",
      "loadall":"` +
      1 +
      `"
    }`
    var response: any = await this.notificationService.loadMoreNotifications(
      requestPayload
    )
    // console.log('onConfirmationClearAllNotifications', response)

    for await (const savedSearch of response) {
      const resp = await this.removeNotifications(savedSearch.ID)
      // console.log('resp', resp)
    }
    this.clearAllNotifications = true
    this.clearAllNotificationsFlag = false

    this.clearAllNotificationsText =
      CONFIG.NOTIFICATIONS.CLEARALLNOTIFICATIONS_SUCCESS_TEXT
    this.notificationToggle = false
    this.showNotificationCount = false
  }

  async removeNotification (updateId: any) {
    console.log("here");

    const requestPayload = {
        "updateId": updateId,
        "search": false,
        "id": ""
    };

    const response = await this.notificationService.removeNotificationUpdate(requestPayload);

    if (response) { // Assuming success flag in response
        // Remove the notification from local updates array
        this.updates = this.updates.filter(update => update.ID !== updateId);

        // Decrement notifications count locally
        this.notificationsCount = Math.max(this.notificationsCount - 1, 0); // Ensures it doesn't go negative

        // Hide the notification count if it reaches zero
        this.showNotificationCount = this.notificationsCount > 0;

        return response;
    } else {
        console.error("Failed to remove notification:", response);
    }

    return null;
}
  async removeNotifications (updateId: any) {
    console.log("here");
    // var requestPayload = {
    //   updateId: "",
    //   search: false,
    //   id: parseInt(updateId)
    // }
    // var requestPayload = `{
    //   "id": `+updateId+`,
    //   "search":true,
    //   "updateId":""
    // }`;
  //   var requestPayload = {
  //     "updateId": updateId,
  //     "search": false,
  //     "id": ""
  // }
  var requestPayload = {
    "id": updateId,
    "search": true,
    "updateId": ""
}

    var response = await this.notificationService.removeNotificationUpdate(
      requestPayload
    )
    // console.log('response removeNotification', response);
    // this.updates.forEach((update, i) => {
    //   if (update.ID == updateId) {
    //     this.updates.splice(i, 1)
    //   }
    // })
    return response
  }
  
  sleep (ms: number) {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }
  clearAll () {
    this.clearAllNotifications = true
  }
  async openNotifications () {
    // console.log("setting isInMaintenance flag");
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
    var resp = await this.Auth.getMaintenanceStatus({
      "checkForMaintainence":true
    });
    // console.log("resp", resp);
          // resp = Boolean(resp);
           // console.log("typeof resp", typeof resp);
         if(resp == "true"){
          sessionStorage.setItem("isInMaintenance", "true");
          this.router.navigateByUrl("/maintenance");
          return;
         }
        }
    // console.log('Open Notifcations called')
    this.notificationToggle = true;
    let requestPayload = {
      userId: this.userid,
      markAsViewed: false,
      updateId: '',
      loadmore: '',
      loadall: '0'
    }
    this.notificationService.getNotifications(requestPayload).subscribe({
      next: (response: any) => {
        // console.log(response)
        let allNotifications = response.map((notification: any) => {
          notification.collapse = 'expand_more'

          return notification
        })
        this.notifications = allNotifications
      },
      error: e => console.error(e),
      complete: () => console.info('complete')
    })
  }

  closeNotificationClearTab () {
    this.clearSavedSearch = false;
    this.isPatternDeleted = false;
    this.notificationToggle = false;
    this.openNotifications();
  }

 
  async onConfirmationRemoveSavedSearch () {
    var requestPayload =
      `{
      "id": ` +
      this.currentSearchId +
      `,
      "search":true,
      "updateId":""
    }`
    var response =
      await this.notificationService.removeSavedSearchFromNotifications(
        requestPayload
      )
    // console.log('removeSavedSearchFromNotifications', response)
    this.notifications.forEach((notification, i) => {
      if ((notification.ID = this.currentSearchId)) {
        this.notifications.splice(i, 1)
      }
    })

    this.clearSavedSearchOperation = true
    this.isPatternDeleted = true
    this.patternName = 'The saved search removed for notifications'; 

    this.elemRef.nativeElement.querySelector(`#remove_${this.currentSearchId}`).remove();
    // var element = sessionStorage.getItem("notificationIdForRemoval");
    // // console.log("element",element);
    //this.elemRef.nativeElement.querySelector(`#${element}`).remove();
    // this.openNotifications();
    if(this.loadMore == false){
   this.loadMore = true;
    }
    if(this.loadMore == true){
      this.loadMore = false;
    }
    // this.notificationToggle = false;
     this.openNotifications();
  }

  clearAllFilters(){
    var facetState = this.engineObj.state.facetSet;
    // console.log("facetState--->"+facetState);

    for (const k in facetState) {
      // console.log("k--->"+JSON.stringify(facetState[k].request.facetId));
      var toggleSelectFacetValueaction1 = loadFacetSetActions(
      this.engineObj
    ).deselectAllFacetValues(facetState[k].request.facetId);
    this.engineObj.dispatch(toggleSelectFacetValueaction1);
    }


    const action = loadAdvancedSearchQueryActions(this.engineObj).updateAdvancedSearchQueries({aq: '',});
    this.engineObj.dispatch(action);

    const actionDf = loadAdvancedSearchQueryActions(this.engineObj).updateAdvancedSearchQueries({dq: '',});
    this.engineObj.dispatch(actionDf);

    const { updateQuery } = loadQueryActions(this.engineObj);
    const { executeSearch } = loadSearchActions(this.engineObj);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engineObj);
    this.engineObj.dispatch(updateQuery({q:''}));
  
    this.engineObj.dispatch(executeSearch(logSearchboxSubmit()));
    this.engineObj.executeFirstSearch();
  }
  

  async removeSavedSearchFromNotifications (patternId: any) {
    this.currentSearchId = patternId;
    this.onConfirmationRemoveSavedSearch();
    // this.clearSavedSearch = true
    // this.notifications.forEach(notification => {
    //   if (notification.ID == patternId) {
    //     // console.log('pattern name', notification.PATTERN_NAME)
    //     sessionStorage.setItem('patternName', notification.PATTERN_NAME);
    //     sessionStorage.setItem('notificationIdForRemoval',patternId);
    //     this.patternName = notification.PATTERN_NAME
    //     return
    //   }
    // })

    // return
   
  }
  oneDriveDocs : any;
  async getOneDriveDocsFromServer(){
    var requestPayload = {
      "userId": this.userId,
      "source": "VVMC"
    }
    var vvmcResponse: any = await (await this.oneDriveService.getDownloadedDocumentsFromOneDrive(requestPayload)).toPromise();
    // console.log("response of docs", vvmcResponse);
    requestPayload.source = "VVQD";
    var vvqdResponse: any = await (await this.oneDriveService.getDownloadedDocumentsFromOneDrive(requestPayload)).toPromise();
    // console.log("response of vvqd", vvqdResponse);

    var allDocs:any;
    var docIds:any;
    try{
      allDocs = [...vvqdResponse, ...vvmcResponse]
      // console.log("All Docs", allDocs);
      docIds = allDocs.map((document) => {
      return document.DOCUMENT_ID;
    });

    }catch(err){

    }
    
    // console.log("docIds", docIds);
    return this.oneDriveDocs = docIds;
  }
  closeNotifications () {
    this.notificationToggle = false
  }


  async loadOneDriveDocs () {
     // start 
     if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      var resp = await this.Auth.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      // console.log("resp", resp);
             //resp = Boolean(resp);
             // console.log("typeof resp", typeof resp);
           if(resp == "true"){
            sessionStorage.setItem("isInMaintenance", "true");
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
    this.clearAllSearch();
    

    this.getOneDriveDocsFromServer().then(res=>{
       this.oneDriveDocs = res;
       this.mydrive.emit(this.oneDriveDocs.length);
    // console.log("oneDriveDocs", this.oneDriveDocs);
    if(this.oneDriveDocs.length==0){
      return;
    }
    let dataforQuery = this.oneDriveDocs.join(",")
    // console.log('dataforQuery',dataforQuery);
    const engine = this.engine.getEngibeObj()
    // const { updateQuery } = loadQueryActions(engine)
    const { executeSearch } = loadSearchActions(engine)
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine)
   
    const action = loadAdvancedSearchQueryActions(
      engine
    ).updateAdvancedSearchQueries({
      // cq: `@contentsourceid=(${dataforQuery})`
      cq: `@source=("Veeva Vault MedComms","Veeva Vault QualityDocs")@contentsourceid=(${dataforQuery})`
    })


    // const { updateQuery } = loadQueryActions(engine);
    // engine.dispatch(updateQuery({ q: `@contentsourceid=(${dataforQuery})` }));


    engine.dispatch(action)
    engine.dispatch(executeSearch(logSearchboxSubmit()))

    })

    
  }

  rotate="no-rotate";
  async startManualSync () {
    // console.log("setting isInMaintenance flag");
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
    var resp = await this.Auth.getMaintenanceStatus({
      "checkForMaintainence":true
    });
    // console.log("resp", resp);
          // resp = Boolean(resp);
           // console.log("typeof resp", typeof resp);
         if(resp == "true"){
          sessionStorage.setItem("isInMaintenance", "true");
          this.router.navigateByUrl("/maintenance");
          return;
         }
        }
    this.rotate = "rotate";
    this.widthOfProgress = 3
    var requestPayload = {
      userId: this.userid,
      offset: 0,
      progressBarCall: 0
    }

    this.oneDriveService
      .startManualSync(requestPayload)
      .subscribe((res: any) => {
        // console.log('startManualSync BAR response-->' + JSON.stringify(res))
        var response = res
        var uniqueId = response.uniqueId
        // console.log('startManualSync BAR response-->' + response)
        if(!response){

          // alert("1");
          this.showPopup = true
          this.title = 'Sync to OneDrive'
          this.body =
            'There is no content downloaded from V&I Search Navigator to your OneDrive.'
            this.widthOfProgress = 0;
            this.rotate = "no-rotate";

            return;

        }
        if (response.status == 230) {
          //auto sync in progress
          this.showPopup = true
          this.title = 'Sync to OneDrive'
          this.body =
            'Auto Sync is in Progress. Can not perform manual sync at this time.'
            this.widthOfProgress = 0;
            this.rotate = "no-rotate";
        } else if (response.status == 231) {
          // alert("2");
          // no content to sync
          this.showPopup = true
          this.title = 'Sync to OneDrive'
          this.body =
            'There is no content downloaded from V&I Search Navigator to your OneDrive.'
            this.widthOfProgress = 0;
            this.rotate = "no-rotate";
        } else {
          setTimeout(() => {
            this.startProgressBar(res, uniqueId)
          }, 3000)
        }
      })
  }

  startProgressBar (res, uniqueId) {
    // console.log('PROGRESS BAR CALLED--->' + JSON.stringify(res))
    var response = res
    var uniqueId = response.uniqueId
    var requestPayload =
      `{
      "userId": `+this.userid+`,
      "uniqueId":"` +
      uniqueId +
      `"
    }`
    this.oneDriveService
      .manualSyncProgress(requestPayload)
      .subscribe((progressVal: any) => {
        var progressBarEle = document.getElementById('progress-color')
        var progressBarElement: HTMLElement = progressBarEle!
        // console.log('PROGRESS RESPONSE-->' + progressVal);

        if(!progressVal && progressVal!=0){
          // alert("3"+progressVal);
          this.showPopup = true
          this.title = 'Sync to OneDrive'
          this.body =
            'There is no content downloaded from V&I Search Navigator to your OneDrive.'
            this.widthOfProgress = 0;
            this.rotate = "no-rotate";

            return;

        }

        var syncProgressValue = progressVal
        syncProgressValue = syncProgressValue == 0 ? 3 : syncProgressValue

        if (syncProgressValue < 100) {
          // this.renderer.setStyle(progressBarElement,'width',syncProgressValue+"%");
          this.widthOfProgress = syncProgressValue
          setTimeout(() => {
            this.startProgressBar(res, uniqueId)
          }, 1000)
        } else {
          this.widthOfProgress = 100;
          this.showPopup = true;
          this.title = 'Sync to OneDrive';
          this.body = 'Sync to OneDrive is Successful';
          this.rotate = "no-rotate";
          // this.renderer.setStyle(progressBarElement,'width',0);
        }
      })
  }

  closePopup () {
    this.showPopup = false;
    this.widthOfProgress = 0;
  }

  wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }

  async loadAllCollections () {
    // console.log("setting isInMaintenance flag");
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
    var resp = await this.Auth.getMaintenanceStatus({
      "checkForMaintainence":true
    });
    // console.log("resp", resp);
           //resp = Boolean(resp);
           // console.log("typeof resp", typeof resp);
         if(resp == "true"){
          this.router.navigateByUrl("/maintenance");
          return;
         }
        }
    // this.clearAllFilters();

    if (this.loadCollectionsPartial) {
      this.loadCollectionsPartial = false
    } else {
      this.loadCollectionsPartial = true
    }

    this.cdRef.detectChanges()
    if (this.collectionComp) {
      this.collectionComp.loadAllCollections()
    }
  }


  collectionslist:any;
  async getCollectionsDocsFromServer(){
    var data = {
      "collectionId": "",
      "userId": this.userid,
      "tab": ""
  }
  this.collectionService.getAllCollections(data).subscribe((collections)=>{
  this.collectionslist = collections.collectionlist;
  // console.log("collectionslist", this.collectionslist);
  //  this.collectionslist= [];
   })
 }

  async showSearches () {
    // console.log("setting isInMaintenance flag");
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
    var resp = await this.Auth.getMaintenanceStatus({
      "checkForMaintainence":true
    });
    // console.log("resp", resp);
          // resp = Boolean(resp);
           // console.log("typeof resp", typeof resp);
         if(resp == "true"){
          this.router.navigateByUrl("/maintenance");
          return;
         }
        }
    // this.clearAllFilters();
    if (this.loadSavedSearchPartial) {
      this.loadSavedSearchPartial = false
    } else {
      this.loadSavedSearchPartial = true
    }

    this.cdRef.detectChanges()
    if (this.savedSearchComp) {
      this.savedSearchComp.loadSearches()
    }
  }

  goToSettings(){
    this.router.navigateByUrl("/settings");
  }


  myCollectionClicked(value:any){
      this.mycollections.emit(value);
  }


  

  clearAllSearch(){



    var facetState = this.engineObj.state.facetSet;
    var facetStateDate = this.engineObj.state.dateFacetSet;
    // console.log("facetState--->"+JSON.stringify(facetState));

    for (const k in facetState) {
      // console.log("k--->"+JSON.stringify(facetState[k].request.facetId));
      var toggleSelectFacetValueaction1 = loadFacetSetActions(
      this.engineObj
    ).deselectAllFacetValues(facetState[k].request.facetId);
    this.engineObj.dispatch(toggleSelectFacetValueaction1);
    }

    var toggleSelectFacetValueaction2 = loadFacetSetActions(
      this.engineObj
    ).deselectAllFacetValues("contentapprovaldate_input");
    this.engineObj.dispatch(toggleSelectFacetValueaction2);


    const action = loadAdvancedSearchQueryActions(this.engineObj).updateAdvancedSearchQueries({aq: '',});
    this.engineObj.dispatch(action);

    const actionDf = loadAdvancedSearchQueryActions(this.engineObj).updateAdvancedSearchQueries({dq: '',});
    this.engineObj.dispatch(actionDf);

    const actionlq = loadAdvancedSearchQueryActions(this.engineObj).updateAdvancedSearchQueries({lq: '',});
    this.engineObj.dispatch(actionlq);

    const actioncq = loadAdvancedSearchQueryActions(this.engineObj).updateAdvancedSearchQueries({cq: '',});
    this.engineObj.dispatch(actioncq);

    const { updateQuery } = loadQueryActions(this.engineObj);
    const { executeSearch } = loadSearchActions(this.engineObj);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engineObj);
    this.engineObj.dispatch(updateQuery({q:''}));
  
    this.engineObj.dispatch(executeSearch(logSearchboxSubmit()));
    this.engineObj.executeFirstSearch();

    this.sharedService.sendclearAll("Clear all btn hit");


    // var toggleSelectFacetValueaction1 = loadFacetSetActions(
    //   this.engine
    // ).deselectAllFacetValues("contentdocumentsubtype");
    // this.engine.dispatch(toggleSelectFacetValueaction1);
    // // const engine = this.engineService.getEngibeObj();
    // const { updateQuery } = loadQueryActions(this.engine);
    // const { executeSearch } = loadSearchActions(this.engine);
    // const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
    // // this.engine.dispatch(updateQuery({q:'eample'}));
    // this.engine.dispatch(executeSearch(logSearchboxSubmit()));
    // this.engine.executeFirstSearch();

    // const facetValueState : FacetValueState ="selected";
    //     const facetValue: FacetValue = {
    //       value: 'External Use, Verbal Only',
    //       state: facetValueState,
    //       numberOfResults: 5
    //     };
    //     const modal: ToggleSelectFacetValueActionCreatorPayload = {
    //       facetId: 'contentintendeduse',
    //       selection: facetValue
    //     };
    //     const toggleSelectFacetValueaction = loadFacetSetActions(this.engine).toggleSelectFacetValue(modal);

    //     const facetValueState2 : FacetValueState ="selected";
    //     const facetValue2: FacetValue = {
    //       value: 'Scientific Content',
    //       state: facetValueState2,
    //       numberOfResults: 5
    //     };
    //     const modal2: ToggleSelectFacetValueActionCreatorPayload = {
    //       facetId: 'csn_medical_affairs_asset_type',
    //       selection: facetValue2
    //     };
    //     const toggleSelectFacetValueaction2 = loadFacetSetActions(this.engine).toggleSelectFacetValue(modal2);

    //     this.engine.dispatch(toggleSelectFacetValueaction);
    //     this.engine.dispatch(toggleSelectFacetValueaction2);  
    //     // const engine = this.engineService.getEngibeObj();
    //     const { updateQuery } = loadQueryActions(this.engine);
    //     const { executeSearch } = loadSearchActions(this.engine);
    //     const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
    //     this.engine.dispatch(updateQuery({q:'eample'}));
    //     this.engine.dispatch(executeSearch(logSearchboxSubmit()));
    //     this.engine.executeFirstSearch();
  }

  redirectTo(redirectURL){
      this.cookieService.set("redirect","",1,'/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
      this.router.navigateByUrl(redirectURL,{
        state:{}
      });
  }

  // Custom Events 

  customEventsLogo(){
    this.eventLoggingService.logCustomEvent('Results Page Menu', { userId: this.cookieService.get('usi'),Action:'Click' },'Click on Results Page Logo');
  }

  customEventClearAll(){
    this.eventLoggingService.logCustomEvent('Results Page Menu', { userId: this.cookieService.get('usi'),Action:'Click' },'Click on Clear');

  }

  customEventManualSync(){
    this.eventLoggingService.logCustomEvent('Results Page Menu', { userId: this.cookieService.get('usi'),Action:'Click' },'Click on Manual Sync');

  }
  
  customEventsLoadOneDrive()
  {
    this.eventLoggingService.logCustomEvent('Results Page Menu', { userId: this.cookieService.get('usi'),Action:'Click' },'Click on Load OneDrive menu');

  }

  customEventsLoadAllCollection()
  {
    try{
      this.eventLoggingService.logCustomEvent('Results Page Menu', { userId: this.cookieService.get('usi'),Action:'Click' },'Click on Load Collection menu');
    }catch(err){

    }
    

  }

  customEventsShowSarches()
  {
    this.eventLoggingService.logCustomEvent('Results Page Menu', { userId: this.cookieService.get('usi'),Action:'Click' },'Click on Saved Searches menu');

  }

  customEventsOpenNotifications()
  {
    this.eventLoggingService.logCustomEvent('Results Page Menu', { userId: this.cookieService.get('usi'),Action:'Click' },'Click on Notifications All menu');

  }

  customEventsClearAll(){
    this.eventLoggingService.logCustomEvent('Results Page Menu', { userId: this.cookieService.get('usi'),Action:'Click' },'Notifications-Clear-All');

  }

  customEventsExpandNotifications(name)
  {
    this.eventLoggingService.logCustomEvent('Results Page Menu', { userId: this.cookieService.get('usi'),Action:'Click',Title : name },'Notifications-Expand');

  }

  customEventsNotificationRemove(name)
  {
    this.eventLoggingService.logCustomEvent('Results Page Menu', { userId: this.cookieService.get('usi'),Action:'Click',Title : name },'Notification-Clear');

  }

}

